import { Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'sans-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'sans-web-play';
}
